

@tailwind base;
@tailwind components;
@tailwind utilities;





@font-face {
  font-family: "Vazir";
  src: url("../public/fonts/Vazir.ttf");
}

body {
  
  font-family: "Vazir";
}
.h1-about{
  font-family: "Vazir";
  font-size: 2.3rem;
  color: #001f3f;
  font-weight: 700;
}

#personal-info {
  width: 80vw;
  height: auto;
  padding: 2rem 0;
  background-image: linear-gradient(rgba(0, 31, 63, 0.4), rgba(0, 36, 63, 0.4)), url("./bg-card.jpg");
}
input{
 color: #666;
}
#hero-image{
  background-color:  #00153f;
  background-image: url(./luillala3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.cards-reasearch{
  background-image: url(./cards.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  

}

@media only screen and (max-width: 600px) {
  .Hero-heading-h1{
line-height: 1.5;
  }
}
.card_1{
  border-radius: 8px;
  
  overflow: hidden;
}
.titles-info{
  flex-basis: 20%;
  color: #001f3f;
  font-weight: 600;
  font-size: 2.4rem;
  padding: 1em;
}
.cards-reasearch img{
  width: 100%;
  height: 30vh;

}
.cctv-h1{
  font-size: 2.4rem;
  font-weight: 600;
  /* color: #666; */
  margin-bottom: 1rem;
}
.cctv-iamge{
  border-radius: 50%;
  width: 80%;
}
.contents-footer ul li h1{

font-size: 1.4rem;
font-weight: 700;
color: #cbcbcb;
  }
.contents-footer ul li a{
display: block;
color: #999;
}
.contents-footer ul li{
 


  
  display: flex;
  flex-direction: column;
  
  gap: 1em;
  }


  @media only screen and  (max-width: 900px) {
    .vedio{
      width: 550px;
      height: 300px;
    }
    .items-footer{
      display: grid;
     
    }
    .cctv-container{
      display: flex;
      flex-direction: column;
   
    }
  
  }
  @media only screen and  (max-width: 640px) {
    .vedio{
      width: 500px;
      height: 310px;
    }
  
  }
  @media only screen and  (max-width: 555px) {
    .vedio{
      width: 500px;
      height: 290px;
    }
    .items-footer{
      justify-content: center;
      align-items: center;
      padding-right: 2rem;
    }
  
  }
  @media only screen and  (max-width: 479px) {
    .vedio{
      width: 420px;
      height: 240px;
    }
  
  }
  @media only screen and  (max-width: 440px) {
    .vedio{
      width: 390px;
      height: 200px;
    }
  
  }
.form-controle  {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}
  
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

  .container-form{
    


    display: flex;
    background-color: #001932;
    
    justify-content: start;
    align-items: flex-start;
    

  }
  
 
  .contents-form{
    flex-basis: 6 4%;
    
  }



  @media only screen and (max-width: 1300px) {
    .section-form{
      display: flex;
      flex-direction: column;
    }
    .form-controle{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 20% 20% 20% 20%;
      gap: 1rem;
      
    }
    .section-form{
      /* height: 200vh; */
        align-items: center; 
      justify-content: start;
      row-gap: 2rem;

    } 
  }
  @media screen and (max-width: 730px) {
  .form-controle{
display: flex;
flex-direction: column;
  }
   .section-form{ 
    /* height: 260vh; */
  }
  .items-part2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .items-part2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;

  }


  }
  
  @media screen and (max-width: 550px) {
    .section-form {
      /* height: 290vh; */
  }

  }

  @media screen and (max-width: 787px) {
    .computer-options{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .computer-options input{
      margin-left: .8rem;
    }

    @media screen and (width<=787px){
      .video{
        display: flex !important;
        flex-direction: column !important;
      }
    }

  }
  .h2-about{
    font-family: "Vazir";
    font-size: 1.7rem;
  }

  
  .company-description {
    font-family: "Vazir"; 
    font-size: 22px;
    line-height: 1.5;
    color: #333;
    text-align: justify;
    padding: 20px;
    /* background-color: ; */
    /* background-color: white; */
   
    border-radius: 5px;
  }
  .about-style{
    font-family: "Vazir";
  }
  @media only screen and (max-width: 600px) {
    .circular-image{
      bottom: 430px;
    }
}
@media only screen and (max-width: 520px) {
  .circular-image{
    bottom: 450px;
  }
}
@media only screen and (max-width: 467px) {
  .circular-image{
    bottom: 470px;
  }
}
@media only screen and (max-width: 430px) {
  .circular-image{
    bottom: 490px;
  }
}
@media only screen and (max-width: 415px) {
  .circular-image{
    bottom: 540px;
  }
}


  

  /* .cctv-image{
    height: 50vh;
    width: 70vw;
    object-fit: cover;
  } */
/* active link */

